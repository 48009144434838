<template>
  <div class="panel">
    <el-form
      ref="outletform"
      :model="outletform"
      label-position="top"
      :rules="rules"
      size="mini"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="6">
          <el-form-item label="Гэрээний төлбөр төлөгдсөн эсэх">
            <el-checkbox v-model="outletform.contract_is_paid">
              Гэрээний төлбөр төлөгдсөн эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Хөнгөлөлтийн хувь"
            prop="contract_discount_percent"
          >
            <el-input-number
              v-model="outletform.contract_discount_percent"
              class="mx-4"
              :min="0"
              :max="100"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Гэрээ дуусах огноо">
            <el-date-picker
              type="date"
              placeholder="Гэрээ дуусах огноо"
              v-model="outletform.contract_expire_at"
              style="width: 100%;"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form label-position="top" size="mini" :rules="rules" ref="zone">
        <el-row :gutter="20" v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])">
          <el-col :span="12" :offset="6">
            <el-form-item prop="desc" label="Зассан шалтгаан">
              <el-input
                type="textarea"
                rows="2"
                v-model="outletform.desc"
                placeholder="Зассан шалтгаан оруулна уу!!!"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20">
        <el-col :span="4" :offset="10">
          <el-button
            type="success"
            size="medium"
            class="full-width"
            @click="updateInformation"
            :disabled="
              !mArrayIncludes(role, [
                'boss',
                'ST',
                'SM',
                'admin',
                'finance',
                'menu_editor'
              ])
            "
            >Хадгалах</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getGroups, getUserName } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  created() {
    this.openFullScreenLoader(true);
    this.getOutletById(this.$route.query._id);
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
  },
  mounted() {
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      loading: null,
      vatName: "",
      role: [],
      outletform: {},
      user: "",
      rules: {
        desc: [
          {
            required: true,
            message: "Зассан шалтгаан оруулна уу",
            trigger: "blur"
          }
        ]
      },
      dialogBool: false,
      addInfo: []
    };
  },
  methods: {
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээлэл татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    nextpage() {
      this.$router.push({
        path: "partner-additional",
        query: {
          _id: this.$route.query._id
        }
      });
    },
    getOutletById(id) {
      const payload = {
        includeFields: [
          "contract_is_paid",
          "contract_discount_percent",
          "contract_expire_at",
          "full_name_eng"
        ]
      };
      service.getOutletById(id, payload).then(outletResponse => {
        this.outletform = outletResponse.data.data;
      });
      this.openFullScreenLoader(false);
    },
    async updateInformation() {
      this.$refs.outletform.validate(async valid => {
        if (valid) {
          this.outletform._id = this.$route.query._id;
          this.outletform.user = this.user;
          this.outletform.action = "updateContractInfo";
          await service
            .editPartnerContractInfo(this.$route.query._id, this.outletform)
            .then(response => {
              this.$router.push({
                path: "partner-additional",
                query: { _id: this.$route.query._id }
              });
              this.$notify({
                title: "Success",
                message: response.data === "" ? "амжилттай" : "амжилттай",
                type: "success"
              });
            });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    }
  }
};
</script>
